<template>
  <b-modal
    id="modal-center"
    @change="hideModal"
    centered
    v-model="isAddNewUserModalActive"
    title="Добавить город"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Название Ru"
              rules="required"
            >
              <b-form-group label="Название Ru" label-for="new-city-title-ru">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="nameRu"
                  id="new-city-title-ru"
                  placeholder="Название Ru"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Название Uz"
              rules="required"
            >
              <b-form-group label="Название Uz" label-for="new-city-title-uz">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="nameUz"
                  id="new-city-title-uz"
                  placeholder="Название Uz"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Название En"
              rules="required"
            >
              <b-form-group label="Название En" label-for="new-city-title-en">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="nameEn"
                  id="new-city-title-en"
                  placeholder="Название En"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Код"
              rules="required"
            >
              <b-form-group label="Код" label-for="new-city-code">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="code"
                  type="number"
                  id="new-city-code"
                  placeholder="Код"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer="{ cancel }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        type="reset"
        class="res-w-100"
        @click="cancel()"
      >
        Отмена
      </b-button>
      <b-button
        class="res-mt-2 res-w-100"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click.prevent="handleSubmit"
      >
        <span class="text-nowrap">Добавить</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormFile,
  BRow,
  BCol,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BButton,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    BFormFile,
    BRow,
    BCol,
    ToastificationContent,
    BFormTextarea,
    flatPickr,
    BFormRadioGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddNewUserModalActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {},
  data() {
    return {
      required,
      nameRu: null,
      nameUz: null,
      nameEn: null,
      code: null,
    };
  },
  methods: {
    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axiosIns
            .post("v1/city/create", {
              nameUz: this.nameUz,
              nameRu: this.nameRu,
              nameEn: this.nameEn,
              code: this.code,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Город был успешно создан!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.hideModal();
              this.$emit("refetch-data");
              setTimeout(() => {
                this.resetForm();
              }, 200);
            });
        }
      });
    },
    hideModal() {
      this.$emit("hideModal");
    },
    resetForm() {
      this.nameRu = null;
      this.nameUz = null;
      this.nameEn = null;
      this.code = null;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
