var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-center","centered":"","title":"Добавить город"},on:{"change":_vm.hideModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"res-w-100",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){return cancel()}}},[_vm._v(" Отмена ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"res-mt-2 res-w-100",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Добавить")])])]}}]),model:{value:(_vm.isAddNewUserModalActive),callback:function ($$v) {_vm.isAddNewUserModalActive=$$v},expression:"isAddNewUserModalActive"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название Ru","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название Ru","label-for":"new-city-title-ru"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-city-title-ru","placeholder":"Название Ru"},model:{value:(_vm.nameRu),callback:function ($$v) {_vm.nameRu=$$v},expression:"nameRu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название Uz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название Uz","label-for":"new-city-title-uz"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-city-title-uz","placeholder":"Название Uz"},model:{value:(_vm.nameUz),callback:function ($$v) {_vm.nameUz=$$v},expression:"nameUz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название En","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название En","label-for":"new-city-title-en"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-city-title-en","placeholder":"Название En"},model:{value:(_vm.nameEn),callback:function ($$v) {_vm.nameEn=$$v},expression:"nameEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Код","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Код","label-for":"new-city-code"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","id":"new-city-code","placeholder":"Код"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }